import React from 'react'
import { Box, Button, Container, Grid, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import TextContainer from '../../elements/textContainer'

const TicketRow = (props) => (
  <Grid
    templateColumns={{ base: '1fr', md: '300px 1fr', lg: '320px 1fr' }}
    bgColor={'white'}
    {...props}
  />
)

const RowContentContainer = (props) => (
  <Box px={{ base: 8, md: 12 }} py={{ base: 8, md: 10 }} {...props} />
)

const HeadingText = (props) => (
  <Text
    fontWeight={'bold'}
    fontSize={'24px'}
    lineHeight={'1.2'}
    textTransform={'capitalize'}
    mb={4}
    {...props}
  />
)

const SubheadingText = (props) => (
  <Text
    fontWeight={'bold'}
    fontSize={'20px'}
    lineHeight={'1.2'}
    textTransform={'capitalize'}
    mb={4}
    {...props}
  />
)

const PriceText = (props) => (
  <Text
    color={'#B0892F'}
    fontSize={'32px'}
    lineHeight={1.2}
    fontFamily={'heading'}
    {...props}
  />
)

const Package = ({
  name,
  description,
  price,
  taxDeductible,
  url,
  color,
  border,
}) => {
  const button = (
    <Button as={'a'} href={url} target={'_blank'}>
      Get Tickets
    </Button>
  )

  return (
    <Grid
      templateColumns={{ base: '1fr', md: '1fr 120px', lg: '1fr 170px' }}
      templateAreas={{
        base: `"heading" "pricing" "description"`,
        md: `"heading pricing"
             "description pricing"`,
      }}
      columnGap={10}
      pt={{ base: 6, md: 10 }}
      borderTop={border ? 'solid 1px #BDBDBD' : null}
      mb={{ base: 4, md: 10 }}
    >
      <Box gridArea={'heading'} display={'flex'} alignItems={'center'} mb={4}>
        <Box width={'20px'} height={'20px'} bgColor={color} mr={2} mt={'2px'} />
        <SubheadingText mb={0}>{name}</SubheadingText>
      </Box>
      <Box>
        <TextContainer
          gridArea={'description'}
          fontSize={{ base: '14px', lg: '16px' }}
        >
          {description}
        </TextContainer>
        <Box
          display={{ base: 'block', md: 'none' }}
          mt={4}
          textAlign={'center'}
        >
          {button}
        </Box>
      </Box>
      <Box
        textAlign={{ base: 'start', md: 'end' }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={{ base: 'flex-start', md: 'flex-end' }}
        gridArea={'pricing'}
        mb={{ base: 4, md: 0 }}
      >
        <PriceText>${price}</PriceText>
        <Text fontSize={'14px'}>
          Of which ${taxDeductible} is a tax deductible donation
        </Text>
        <Box mt={'auto'} display={{ base: 'none', md: 'block' }}>
          {button}
        </Box>
      </Box>
    </Grid>
  )
}

export function LaEventTicketSection() {
  return (
    <Box bgColor={'#F6F2E9'} py={12} id={'tickets'} scrollMarginTop={'60px'}>
      <Container maxW={'container.lg'} px={{ base: 0, md: 8 }}>
        <Text
          color={'#B0892F'}
          fontFamily={'heading'}
          fontSize={'40px'}
          mb={4}
          px={{ base: 4, md: 0 }}
        >
          Tickets
        </Text>

        <TicketRow mb={{ base: 6, md: 10 }}>
          <Box>
            <StaticImage
              src={'../../../images/index/la/venue-stage.jpg'}
              alt={'Stage at the Shrine Auditorium'}
              style={{ height: '100%' }}
              objectFit={'cover'}
              objectPosition={'center center'}
            />
          </Box>
          <RowContentContainer display={'flex'}>
            <Box>
              <HeadingText>Auditorium and Mezzanine Seating</HeadingText>
              <Text mb={4}>
                <strong>Please note</strong> that a convenience fee (10-20%)
                will be applied by Axs.com at checkout. If you do not want to
                incur the convenience fee, you can purchase the tickets in
                person at the theater’s box office. Click here for the box
                office hours.{' '}
              </Text>
              <Button
                as={'a'}
                href={
                  'https://www.axs.com/events/451891/an-evening-with-sadhguru-tickets'
                }
                target={'_blank'}
              >
                Buy Tickets
              </Button>
            </Box>

            <Box flexShrink={0} ml={8} display={{ base: 'none', md: 'block' }}>
              <PriceText>
                $46, $71,
                <Box as={'br'} display={{ base: 'none', md: 'inline' }} />
                $96, $167
              </PriceText>
            </Box>
          </RowContentContainer>
        </TicketRow>

        <TicketRow>
          <Box
            gridRow={{ base: 2, md: 'unset' }}
            position={{ base: 'sticky', md: 'unset' }}
            top={{ base: '64px', md: 'unset' }}
            zIndex={1}
          >
            <StaticImage
              src={'../../../images/index/la/stage-map.jpg'}
              alt={'Seating map for the Shrine Auditorium'}
              style={{ height: '100%' }}
              objectFit={'contain'}
              objectPosition={'center center'}
            />
          </Box>
          <RowContentContainer pr={{ base: 0, md: '112px' }}>
            <HeadingText>Sponsorship Seating</HeadingText>
            <TextContainer fontSize={{ base: '14px', md: '16px' }}>
              <p style={{ fontWeight: 'bold' }}>
                Please note the sponsorship tickets are only available on this
                page and cannot be purchased on Axs.com
              </p>
              <p>
                <strong>Ticket Pickup:</strong> The sponsorship category tickets
                can be collected upon arrival at Shrine Auditorium on the day of
                the event.
              </p>
              <p>
                <strong>Rule of Seat Assignment:</strong> The best available
                seat within the seating area will be assigned according to your
                time of booking.
              </p>
            </TextContainer>
          </RowContentContainer>

          <RowContentContainer bgColor={'blue'} color={'white'}>
            <SubheadingText color={'white'}>
              Front Seating Section
            </SubheadingText>
            <Text fontSize={{ base: '14px', lg: '16px' }}>
              Buy any package to support Isha Foundation's initiatives.
            </Text>
            <Box my={6} borderTop={'solid 1px white'} />
            <Text fontSize={{ base: '14px', lg: '16px' }}>
              Isha Foundation is a non-profit organization supported by over 17
              million volunteers worldwide. By opting for a sponsorship package,
              you are supporting Isha’s endeavor to craft a healthy, conscious
              and inclusive humanity through the powerful science of Yoga. A
              significant portion of the funds from these packages will go
              towards furthering the much-needed developmentof Isha Yoga Center
              LA.
            </Text>
          </RowContentContainer>

          <RowContentContainer
            py={0}
            pb={6}
            borderTop={'solid 1px'}
            borderColor={'#BDBDBD'}
          >
            <Package
              name={'Donor Package'}
              description={
                <>
                  This package includes:
                  <ul>
                    <li>
                      Front section seating behind the Sponsor and Patron
                      categories
                    </li>
                    <li>Priority check in at the event</li>
                    <li>Karma: A Yogi’s Guide to Crafting Your Destiny book</li>
                  </ul>
                </>
              }
              border={false}
              price={'509'}
              taxDeductible={'250'}
              color={'#B5BC39'}
              url={'https://form.jotform.com/222845272718057'}
            />

            <Package
              name={'Sponsor Package'}
              description={
                <>
                  This package includes:
                  <ul>
                    <li>Premium seating</li>
                    <li>Priority check in at the event</li>
                    <li>
                      Karma: A Yogi’s Guide to Crafting Your Destiny book signed
                      by Sadhguru
                    </li>
                    <li> A copper water bottle</li>
                  </ul>
                </>
              }
              border={true}
              price={'912'}
              taxDeductible={'650'}
              color={'#087399'}
              url={'https://form.jotform.com/222845063123146'}
            />

            <Package
              name={'Patron Package'}
              description={
                <>
                  This package includes:
                  <ul>
                    <li>Premium seating (frontmost seats)</li>
                    <li>Priority check in at the event</li>
                    <li>
                      Karma: A Yogi’s Guide to Crafting Your Destiny book signed
                      by Sadhguru
                    </li>
                    <li>An organic cotton Karma Hoodie</li>
                    <li>A copper water bottle</li>
                  </ul>
                </>
              }
              border={true}
              price={'5,050'}
              taxDeductible={'4,750'}
              color={'#DA692C'}
              url={'https://form.jotform.com/222845304129150'}
            />
          </RowContentContainer>
        </TicketRow>
      </Container>
    </Box>
  )
}

import React, { useState } from 'react'
import { Box, Button, Collapse, Container, Link, Text } from '@chakra-ui/react'
import TextContainer from '../../elements/textContainer'
import { ChevronDownIcon } from '@chakra-ui/icons'

const faqItems = [
  {
    question: 'Who can attend?',
    answer: (
      <p>
        The event is open to anyone aged 12 and over. No physical agility or
        prior experience in Yoga or meditation is required.{' '}
      </p>
    ),
  },
  {
    question: 'Do I need to purchase a ticket to attend?',
    answer: (
      <p>
        Yes, you need to purchase a ticket to attend. We offer a range of
        different seating categories and fee options. Please pre-register for
        the event{' '}
        <Link href={'#pre-registration'} color={'gold'}>
          here
        </Link>
        .
      </p>
    ),
  },
  {
    question: 'Where can I purchase a ticket?',
    answer: (
      <>
        <p>
          Tickets are not yet available for purchase. However, you may
          pre-register for the event by clicking{' '}
          <Link href={'#pre-registration'} color={'gold'}>
            here
          </Link>
          . By pre-registering, you will receive priority access via email to
          purchase tickets 24 hours before tickets are available to all.
        </p>
        <p>
          We recommend that you pre-register to ensure that you receive early
          access to purchase tickets. Sadhguru's events are very popular, and
          capacity is limited!
        </p>
        <p>
          Please note that pre-registering does not guarantee that your ticket
          has been reserved. You must visit the ticketing website and purchase
          your tickets within the 24-hour window after the pre-purchase link has
          been emailed to you.
        </p>
      </>
    ),
  },
  {
    question: 'When will the link to pre-purchase tickets be available?',
    answer: (
      <p>
        We currently do not have a confirmed date when tickets will be available
        for pre-purchase. Please sign up for pre-registration. Due to
        overwhelming interest, the event is expected to fill up quickly. By
        reserving your spot to pre-purchase tickets, you will receive priority
        access via email to the ticketing website 24 hours before tickets are
        available to all.
      </p>
    ),
  },

  {
    question: 'What is the event schedule?',
    answer: (
      <p>
        Doors open at 4:00 PM. The program begins promptly at 5:00 PM and ends
        at 7:00 PM. Please arrive 1 hour early for check-in and seating. We
        apologize in advance as we may not be able to accommodate latecomers.
      </p>
    ),
  },
  {
    question: 'Can I bring my children?',
    answer: (
      <p>
        No children under 12 will be admitted due to the nature of the program.
        Unfortunately, we will not be able to provide childcare for the event.
      </p>
    ),
  },
  {
    question: 'How do I get to the venue?',
    answer: (
      <p>
        The Shrine Auditorium is easy to locate. The website provides several
        travel, parking, and public transportation options. To learn more please{' '}
        <Link
          href={
            'https://www.shrineauditorium.com/general-info/plan-your-visit/directions'
          }
          color={'gold'}
          isExternal={true}
        >
          visit the Shrine Auditorium website
        </Link>
        .
      </p>
    ),
  },
  {
    question: 'What should I bring for the event?',
    answer: (
      <p>
        Bring your ticket, either printed or mobile. Please note that
        photography, audio and video recording are not permitted.
      </p>
    ),
  },
  {
    question: 'Can I bring water and food with me?',
    answer: (
      <>
        <p>
          No outside food or beverages are permitted inside the venue. You may
          purchase items at the concession stand. We recommend you eat dinner
          before or after the event.
        </p>
        <p>
          Due to the nature of the event and meditations offered, any
          consumption of alcohol or intoxicants may compromise your experience
          of what Sadhguru is offering. We hence highly recommend that you
          refrain from alcohol consumption before the event.
        </p>
      </>
    ),
  },
  {
    question: 'What should I wear?',
    answer: (
      <p>
        There is no required dress code. However, we recommend that you wear
        loose, light and comfortable clothing for the best experience.
      </p>
    ),
  },
  {
    question: 'Will I learn a physical Yoga practice?',
    answer: (
      <p>
        There will be a meditation session with Sadhguru but not a physical yoga
        practice. You will be seated in a chair for the duration of the program.
      </p>
    ),
  },

  {
    question: 'Is this event CME credited?',
    answer: <p>There are no CME/CE credits available for this program.</p>,
  },
  {
    question: 'Can I get a refund if I am unable to attend?',
    answer: (
      <p>
        Unfortunately, we are unable to provide refunds for this event. Please
        refer to the{' '}
        <Link
          href={'https://www.ticketmaster.com/h/returns.html'}
          color={'gold'}
          isExternal={true}
        >
          Cancellation Policy
        </Link>{' '}
        and review the{' '}
        <Link
          href={
            'https://help.ticketmaster.com/s/article/Terms-of-Use?language=en_US&tm_link=_terms'
          }
          color={'gold'}
          isExternal={true}
        >
          Terms & Conditions
        </Link>
        .
      </p>
    ),
  },
  {
    question: 'What are the health and Covid policies for the event?',
    answer: (
      <>
        <p>
          Click{' '}
          <Link
            href={'https://www.goldenvoice.com/health-policy/'}
            color={'gold'}
            isExternal={true}
          >
            HERE
          </Link>{' '}
          for the most recent Health Policy information for The Shrine
          Auditorium.
        </p>
        <p>
          Please click{' '}
          <Link
            href={
              'https://isha.sadhguru.org/in/en/center/isha-institute-inner-sciences-usa/iii-health-and-safety-terms'
            }
            color={'gold'}
            isExternal={true}
          >
            HERE
          </Link>{' '}
          for Isha Foundation’s Health and Safety Terms.
        </p>
      </>
    ),
  },
  {
    question: 'Can I purchase multiple event tickets for friends and family?',
    answer: (
      <p>Yes, you may purchase multiple tickets for your friends and family.</p>
    ),
  },
  {
    question: 'Who can I contact for more information about the program?',
    answer: (
      <p>
        For questions about the program or the Isha Foundation, please contact
        us at{' '}
        <Link href={'mailto:la@ishausa.org'} color={'gold'} isExternal={true}>
          la@ishausa.org
        </Link>{' '}
        or call{' '}
        <Link href={'tel:(323) 929-7098'} color={'gold'} isExternal={true}>
          (323) 929-7098
        </Link>
      </p>
    ),
  },
]

function FaqItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Box
      key={question}
      py={3}
      borderBottom={'solid 1px'}
      borderColor={'#CFCDCD'}
    >
      <Box
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        cursor={'pointer'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Text fontSize={'20px'}>{question}</Text>
        <ChevronDownIcon
          fontSize={'20px'}
          style={{
            transform: isOpen ? 'rotate(180deg)' : 'unset',
            transition: '.3s all',
          }}
        />
      </Box>
      <Collapse in={isOpen}>
        <TextContainer mt={3}>{answer}</TextContainer>
      </Collapse>
    </Box>
  )
}

export function LaEventFaqSection() {
  const [showAll, setShowAll] = useState(false)

  const visibleFaqItems = showAll ? faqItems : faqItems.slice(0, 5)

  return (
    <Box pt={16} pb={showAll ? 16 : 10} borderBottom={'solid 1px #CFCDCD'}>
      <Container maxW={'container.lg'}>
        <Text
          fontFamily={'heading'}
          fontSize={{ base: '28px', md: '40px' }}
          lineHeight={'1.2'}
          color={'gold'}
          mb={4}
          textAlign={'center'}
        >
          Frequently Asked Questions
        </Text>

        {visibleFaqItems.map(({ question, answer }, index) => (
          <FaqItem question={question} answer={answer} key={index} />
        ))}

        {!showAll && (
          <Box textAlign={'center'} mt={8}>
            <Button
              onClick={() => {
                setShowAll(true)
              }}
            >
              View More
            </Button>
          </Box>
        )}
      </Container>
    </Box>
  )
}

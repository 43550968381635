import React from 'react'
import { Box, Container, Grid, Link, Text, VStack } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'

const VenueLink = (props) => (
  <Link
    color={'gold'}
    textDecoration={'underline'}
    fontWeight={'bold'}
    isExternal={true}
    {...props}
  />
)

export function LaVenueDetails() {
  return (
    <Box
      position={'relative'}
      bgColor={'#02003D'}
      pt={12}
      pb={{ base: 0, sm: 12 }}
    >
      <Container maxW={'container.lg'}>
        <Grid
          templateColumns={{ base: '1fr', sm: '200px 1fr', md: '270px 1fr' }}
        >
          <VStack maxW={'270px'} alignItems={'flex-start'} spacing={4}>
            <Text
              fontFamily={'heading'}
              color={'white'}
              fontSize={'40px'}
              lineHeight={'48px'}
            >
              Venue Details
            </Text>
            <Text color={'white'}>
              <VenueLink href={'https://www.shrineauditorium.com/'}>
                Shrine Auditorium
              </VenueLink>
              <br />
              665 W Jefferson Blvd.
              <br />
              Los Angeles, CA 90007
            </Text>

            <VenueLink
              href={
                'https://www.shrineauditorium.com/ticket-info/seating-charts'
              }
            >
              View the seating map
            </VenueLink>

            <Text as={'div'} color={'white'}>
              <Text as={'div'} fontWeight={'bold'} mb={2}>
                Public Transit
              </Text>
              The Shrine Auditorium & Expo Hall is easily accessible via the
              Metro Expo Line (5 minute walk from Jefferson/USC station) and
              multiple bus lines.
            </Text>
          </VStack>
        </Grid>
      </Container>
      <Box
        position={{ base: 'static', sm: 'absolute' }}
        top={0}
        right={0}
        bottom={0}
        width={{
          base: '100%',
          sm: 'calc(100vw - 240px)',
          md: 'calc(100vw - 360px)',
          // Calculated by taking full screen width and subtracting text content
          // width and the margin after container is applied
          lg: 'calc(100vw - 360px - ((100vw - 1024px) / 2))',
        }}
        overflow={'hidden'}
        mt={{ base: 12, sm: 0 }}
      >
        <StaticImage
          src={'../../../images/index/la/venue-map.jpg'}
          alt={'Venue Map'}
          layout={'fullWidth'}
          objectFit={'cover'}
          objectPosition={'27% 100%'}
          style={{ height: '100%' }}
        />
      </Box>
    </Box>
  )
}

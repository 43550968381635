import {
  Box,
  Flex,
  Container,
  Grid,
  Heading,
  Text,
  Skeleton,
  Stack,
  Image,
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import SadhguruSignature from '../../../images/common/sadhguru-signature.inline.svg'
import TextContainer from '../../elements/textContainer'
import React from 'react'
import { useGeo } from '../../../api/queries'
import PenguinLogo from '../../elements/penguinLogo'
import { regions } from '../../../api/constants'
import newYorkTimes from '../../../images/common/nytBestseller.svg'

export function AboutBookSection() {
  const {
    geo: { region },
    isSuccess,
  } = useGeo()
  return (
    <Container
      py={12}
      maxWidth={'container.lg'}
      id={'about-book'}
      marginTop={{ base: 4, md: 10 }}
    >
      <Grid
        templateColumns={{
          base: '1fr',
          sm: 'minmax(150px, 1fr) 1.5fr',
          lg: '400px 1fr',
        }}
        columnGap={12}
        rowGap={12}
      >
        <div>
          <Box
            w={'100%'}
            maxW={{ base: '200px', sm: '300px', md: 'unset' }}
            mb={[5, 2]}
            mx={'auto'}
            transform={'scale(1.05)'}
            transformOrigin={'top left'}
          >
            {!isSuccess && <Skeleton w={'100%'} pb={'150%'} />}
            {isSuccess &&
              (region === regions.india ? (
                <StaticImage
                  src={'../../../images/index/bookImageIndia.png'}
                  alt={'Karma Book'}
                  width={500}
                />
              ) : region === regions.oceania ? (
                <StaticImage
                  src={'../../../images/index/bookImageAustralia.png'}
                  alt={'Karma Book'}
                  width={500}
                />
              ) : (
                <StaticImage
                  src={'../../../images/index/bookImage.png'}
                  alt={'Karma Book'}
                  width={500}
                />
              ))}
          </Box>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            w={'100%'}
            maxW={['150px', '70%', '360px']}
            mx={'auto'}
            textAlign={'center'}
            spacing={{ base: 4, md: 0 }}
            alignItems={'center'}
            pt={4}
          >
            <Image
              src={newYorkTimes}
              htmlWidth={'797.49px'}
              htmlHeight={'191.42px'}
              width={{ base: '100%', md: '56%' }}
              order={{ base: -1, md: 1 }}
              ml={{ base: 0, md: 4 }}
              mb={{ base: 0, md: 2 }}
            />
            <Box width={{ base: '100%', md: '44%' }}>
              <PenguinLogo />
            </Box>
          </Stack>
        </div>
        <Box>
          <Box maxWidth={'540px'} mb={12}>
            <Heading textStyle={'heading'} mb={4} color={'gold'}>
              “Karma means that you can change your destiny, not simply be ruled
              by it.”
            </Heading>
            <Box>
              <SadhguruSignature />
            </Box>
          </Box>
          <TextContainer>
            <p>
              Most people understand karma as a balance sheet of good and bad
              deeds, virtues and sins. The mechanism that decrees that we cannot
              evade the consequences of our own actions. In reality, karma has
              nothing to do with reward and punishment. Karma simply means
              action: your action, your responsibility. It is not some external
              system of crime and punishment, but an internal cycle generated by
              you. Accumulation of karma is determined only by your intention
              and the way you respond to what is happening to you. Over time, it
              is possible to become ensnared by your own unconscious patterns of
              behavior.
            </p>

            <p>
              A new perspective on the commonly used and misinterpreted concept
              of “karma” that offers the key to happiness and enlightenment,
              from the New York Times bestselling author and world-renowned yogi
              and mystic, Sadhguru.
            </p>
          </TextContainer>
        </Box>

        <Flex justifyContent={'center'} alignItems={'flex-start'}>
          <StaticImage
            src={'../../../images/index/sadhguruRadiantImage.jpg'}
            alt={'Sadhguru'}
            width={300}
          />
        </Flex>
        <Box>
          <Text>
            Sadhguru's ability to make complex ancient concepts accessible to
            modern audiences catapulted his previous book Inner Engineering: A
            Yogi’s Guide to Joy onto the New York Times bestseller list. Over
            the past four decades, Sadhguru has drawn millions of people to his
            yoga and meditation programs and has established Isha Foundation,
            supported by over 11 million volunteers in 300 cities worldwide to
            create an inclusive and conscious world. He has addressed
            prestigious international forums such as the United Nations, the
            World Economic Forum, and TED. As a visionary behind several
            massive-scale humanitarian projects in public health, education, and
            ecology, Sadhguru inspires millions to dedicate their time and
            energy to create an inclusive and conscious world.
          </Text>
        </Box>
      </Grid>
    </Container>
  )
}

import React from 'react'
import { OrderSection } from '../components/page/orderSection'
import { QuizSection } from '../components/page/index/quiz/quizSection'
import EndorsementsSection from '../components/page/index/endorsementsSection'
import Layout from '../components/layout/layout'
import { AboutBookSection } from '../components/page/index/aboutBookSection'
import metaImage from '../images/index/socialImage.jpg'
import { LaEventInfoSection } from '../components/page/index/laEventInfoSection'
import { LaVenueDetails } from '../components/page/index/laVenueDetails'
import { LaEventHeaderSection } from '../components/page/index/laEventHeaderSection'
import { LaEventFaqSection } from '../components/page/index/laEventFaqSection'
import AccoladesSection from '../components/page/index/accoladesSection'
import { LaEventTicketSection } from '../components/page/index/laEventTicketSection'

export default function HomePage() {
  return (
    <Layout
      fadeNav={false}
      meta={{
        title:
          'Karma Book by Sadhguru: A Yogi’s Guide to Crafting Your Destiny',
        description:
          'A new perspective on the overused and misunderstood concept of “karma” that offers the key to happiness and enlightenment, from the New York Times bestselling author and world-renowned yogi and mystic, Sadhguru.',
        image: metaImage,
      }}
      showPenguinLogo={false}
      showNavEventsButton={false}
    >
      <LaEventHeaderSection />
      <LaEventInfoSection />
      <LaEventTicketSection />
      <LaVenueDetails />
      <LaEventFaqSection />

      <AccoladesSection />
      <AboutBookSection />
      <EndorsementsSection />
      <QuizSection />
      <OrderSection />
    </Layout>
  )
}

import { Box, Button, Text } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { scrollIntoViewById } from '../../../utils/scroll'

export function LaEventHeaderSection() {
  return (
    <Box position={'relative'} bgColor={'blue'}>
      <Box display={['block', 'none']} pt={'24vw'}>
        <StaticImage
          src={'../../../images/index/la/header-mobile.jpg'}
          alt={'Header Image'}
          loading={'eager'}
          layout={'fullWidth'}
          quality={85}
        />
      </Box>
      <Box display={['none', 'block']}>
        <StaticImage
          src={'../../../images/index/la/header-desktop.jpg'}
          alt={'Header Image'}
          loading={'eager'}
          layout={'fullWidth'}
          quality={85}
        />
      </Box>
      <Box
        position={'absolute'}
        top={0}
        bottom={{ base: 'unset', sm: 0 }}
        left={0}
        width={{ base: '100%', sm: '60%' }}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        fontSize={{ base: '3.3vw', sm: '1.9vw', lg: '1.7vw' }}
        textTransform={'uppercase'}
        pt={{ base: '3vw', sm: 0 }}
        textAlign={'center'}
      >
        <Box width={'auto'}>
          <Text as={'h1'} color={'#CBA62D'} lineHeight={1}>
            <Text as={'span'} fontSize={'0.9em'}>
              An Evening with YOGI, MYSTIC, VISIONARY
            </Text>
            <br />
            <Text as={'span'} fontSize={'3.9em'} fontFamily={'heading'}>
              SADHGURU
            </Text>
          </Text>

          <Box
            borderTop={'solid 1px'}
            borderColor={'gold'}
            width={'100%'}
            mt={'.8em'}
            mb={'1.3em'}
            maxW={{ base: '21em' }}
          />

          <Text
            as={'div'}
            fontWeight={'bold'}
            fontSize={'1.18em'}
            color={'white'}
            lineHeight={1}
          >
            Karma – Master Your Destiny
          </Text>
          <Text
            as={'div'}
            fontWeight={'bold'}
            fontSize={'1.65em'}
            color={'white'}
          >
            LOS ANGELES, OCT 30TH
          </Text>

          <Box textAlign={{ base: 'center', sm: 'start' }}>
            <Button
              onClick={() => scrollIntoViewById('tickets')}
              color={'#02003D'}
              bgColor={'#CBA72D'}
              _hover={{ bgColor: '#9c8026' }}
              _focus={{ bgColor: '#9c8026' }}
              _active={{ bgColor: '#816a20' }}
              mt={'1em'}
              fontSize={{ base: '1.65em', sm: '1.1em', md: '0.8em' }}
              height={'2.2em'}
              px={'1.4em'}
            >
              Get Tickets
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

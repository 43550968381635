import React from 'react'
import { Box, Container, Grid, Text } from '@chakra-ui/react'
import TextContainer from '../../elements/textContainer'
import { EventInfoHighlights } from './eventInfoHighlights'
import { StaticImage } from 'gatsby-plugin-image'

export function LaEventInfoSection() {
  return (
    <Box pb={8} pt={{ base: '90px', sm: '120px' }} position={'relative'}>
      <Container maxW={'container.lg'}>
        <Grid
          templateColumns={{ base: '1fr', md: '300px 1fr' }}
          columnGap={'120px'}
        >
          <EventInfoHighlights variant={'main'} />
          <Box>
            <Text
              fontFamily={'heading'}
              fontSize={{ base: '28px', md: '40px' }}
              lineHeight={'1.2'}
              color={'gold'}
              mb={4}
            >
              Karma – Master Your Destiny
            </Text>
            <Text
              fontSize={{ base: '16px', md: '24px' }}
              lineHeight={'1.25'}
              mb={4}
            >
              An Evening with Sadhguru on Oct 30th from 5 - 7 PM at the Shrine
              Auditorium in Los Angeles.
            </Text>
            <TextContainer mb={8}>
              <p>
                Join us for this extraordinary opportunity to be in the presence
                of Sadhguru as he unravels the existential mechanism of karma, a
                dimension that puts you back in the driver’s seat of your life.
                Sadhguru will also guide the audience through a deeply
                transformative meditation.
              </p>
              <p>
                Come and explore compelling questions about karma and the most
                vital areas of human inquiry: the meaning of life and, above
                all, how to live at full throttle.
              </p>
              <p>Open to all ages 12 and above.</p>
            </TextContainer>
          </Box>
        </Grid>
      </Container>

      <Box
        position={'absolute'}
        top={0}
        left={0}
        width={{ base: '100vw', sm: '80vw', md: '70vw', lg: '60vw' }}
        zIndex={-1}
      >
        <StaticImage
          src={'../../../images/index/la/leaf-design-blue.jpg'}
          alt={''}
          layout={'fullWidth'}
        />
      </Box>
    </Box>
  )
}

import { Box, Text, VStack } from '@chakra-ui/react'
import React from 'react'

export function EventInfoHighlights({ variant }) {
  const daysLeft = new Date(2022, 9, 30).getDate() - new Date().getDate()

  return (
    <VStack
      spacing={8}
      textAlign={'left'}
      alignItems={'flex-start'}
      display={{ base: 'none', md: 'flex' }}
    >
      {[
        variant === 'dialog' && {
          text: 'Karma – Master Your Destiny',
          subtext: 'An Evening With Sadhguru',
        },
        {
          text: `Starts in ${daysLeft} days`,
          subtext: 'Sunday, Oct 30th, 2022',
        },
        { text: '2 hours', subtext: '5 - 7 PM PST' },
        {
          text: 'Shrine Auditorium',
          subtext: (
            <>
              665 W Jefferson Blvd.
              <br />
              Los Angeles, CA 9007
            </>
          ),
        },
        { text: 'Open to All Ages 12 +' },
      ]
        .filter((x) => x)
        .map(({ text, subtext }) => (
          <Box key={text}>
            <Text
              fontFamily={'heading'}
              fontSize={variant === 'dialog' ? '24px' : '30px'}
              lineHeight={'1.2'}
            >
              {text}
            </Text>
            {subtext && (
              <>
                <Text
                  fontSize={variant === 'dialog' ? '14px' : '20px'}
                  lineHeight={'1.2'}
                >
                  {subtext}
                </Text>
              </>
            )}
          </Box>
        ))}
    </VStack>
  )
}
